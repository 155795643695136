import {
  DashboardOutlined,
  TeamOutlined,
  SettingOutlined,
  PieChartOutlined,
  UnorderedListOutlined,
  EnvironmentOutlined,
  BankOutlined,
  AppstoreOutlined,
  InsertRowBelowOutlined,
  UserSwitchOutlined,
  BarChartOutlined,
  CalculatorOutlined,
  ReloadOutlined,
  CreditCardOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH, TILL_PREFIX_PATH } from "configs/AppConfig";
import { cookieGETAdminID } from "cookie/general";

const dashBoardNavTree =
  cookieGETAdminID() === 2
    ? [
        {
          key: "dashboards",
          path: `${APP_PREFIX_PATH}/dashboards`,
          title: "sidenav.dashboard",
          icon: DashboardOutlined,
          breadcrumb: false,
          submenu: [],
        },
      ]
    : [];

const tillNavTree = [
  {
    key: "till",
    path: `${TILL_PREFIX_PATH}/login`,
    title: "sidenav.till",
    icon: LogoutOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const setupNavTree =
  cookieGETAdminID() === 2
    ? [
        {
          key: "setup",
          path: `${APP_PREFIX_PATH}/setup`,
          title: "sidenav.setup",
          icon: SettingOutlined,
          breadcrumb: false,
          submenu: [
            {
              key: "setup-company",
              path: `${APP_PREFIX_PATH}/setup/company/details`,
              title: "sidenav.setup.company",
              icon: TeamOutlined,
              breadcrumb: false,
              submenu: [
                /*
        {
          key: 'setup-company-receipts',
          path: `${APP_PREFIX_PATH}/setup/company/reciepts`,
          title: 'sidenav.setup.company.reciepts',
          icon: '',
          breadcrumb: false,
          submenu: []
        },*/
                {
                  key: "setup-company-discount",
                  path: `${APP_PREFIX_PATH}/setup/company/discount`,
                  title: "sidenav.setup.company.discount",
                  icon: "",
                  breadcrumb: false,
                  submenu: [],
                },
                /*{
          key: 'setup-company-refund',
          path: `${APP_PREFIX_PATH}/setup/company/refund`,
          title: 'sidenav.setup.company.refund',
          icon: '',
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'setup-company-no-sale',
          path: `${APP_PREFIX_PATH}/setup/company/no-sale`,
          title: 'sidenav.setup.company.no-sale',
          icon: '',
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'setup-company-stock',
          path: `${APP_PREFIX_PATH}/setup/company/stock`,
          title: 'sidenav.setup.company.stock',
          icon: '',
          breadcrumb: false,
          submenu: []
        }*/
              ],
            },
            {
              key: "setup-locations",
              path: `${APP_PREFIX_PATH}/setup/locations`,
              title: "sidenav.setup.locations",
              icon: EnvironmentOutlined,
              breadcrumb: false,
              submenu: [
                {
                  key: "setup-locations-devices",
                  path: `${APP_PREFIX_PATH}/setup/locations/branches`,
                  title: "sidenav.setup.locations.branches",
                  icon: "",
                  breadcrumb: false,
                  submenu: [],
                },
                /*{
          key: 'setup-locations-areas',
          path: `${APP_PREFIX_PATH}/setup/locations/areas`,
          title: 'sidenav.setup.locations.areas',
          icon: '',
          breadcrumb: false,
          submenu: []
        }*/
              ],
            },
            {
              key: "setup-banking",
              path: `${APP_PREFIX_PATH}/setup/banking`,
              title: "sidenav.setup.banking",
              icon: BankOutlined,
              breadcrumb: true,
              submenu: [
                {
                  key: "setup-banking-tender-types",
                  path: `${APP_PREFIX_PATH}/setup/banking/tender-types`,
                  title: "sidenav.setup.banking.tender",
                  icon: "",
                  breadcrumb: false,
                  submenu: [],
                },
                {
                  key: "setup-banking-tax-rates",
                  path: `${APP_PREFIX_PATH}/setup/banking/tax-rates`,
                  title: "sidenav.setup.banking.tax",
                  icon: "",
                  breadcrumb: false,
                  submenu: [],
                },
                /*{
          key: 'setup-banking-petty-cash',
          path: `${APP_PREFIX_PATH}/setup/banking/petty-cash`,
          title: 'sidenav.setup.banking.petty',
          icon: '',
          breadcrumb: false,
          submenu: []
        }*/
              ],
            },
          ],
        },
      ]
    : [];

const megaAdminView = [
  {
    key: "manage-staff-admin",
    path: `${APP_PREFIX_PATH}/manage/staff/admin`,
    title: "sidenav.manage.staff.admin",
    icon: "",
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "manage-staff-staff",
    path: `${APP_PREFIX_PATH}/manage/staff/staff`,
    title: "sidenav.manage.staff.staff",
    icon: "",
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "manage-staff-roles",
    path: `${APP_PREFIX_PATH}/manage/staff/roles`,
    title: "sidenav.manage.staff.roles",
    icon: "",
    breadcrumb: false,
    submenu: [],
  } /*,{
  key: 'manage-staff-hours',
  path: `${APP_PREFIX_PATH}/manage/staff/hours`,
  title: 'sidenav.manage.staff.hours',
  icon: '',
  breadcrumb: false,
  submenu: []
}*/,
];

const adminView = [
  {
    key: "manage-staff-staff",
    path: `${APP_PREFIX_PATH}/manage/staff/staff`,
    title: "sidenav.manage.staff.staff",
    icon: "",
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "manage-staff-roles",
    path: `${APP_PREFIX_PATH}/manage/staff/roles`,
    title: "sidenav.manage.staff.roles",
    icon: "",
    breadcrumb: false,
    submenu: [],
  },
  /*{
  key: 'manage-staff-hours',
  path: `${APP_PREFIX_PATH}/manage/staff/hours`,
  title: 'sidenav.manage.staff.hours',
  icon: '',
  breadcrumb: false,
  submenu: []
}*/
];

const manageNavTree = [
  {
    key: "manage",
    path: `${APP_PREFIX_PATH}/manage`,
    title: "sidenav.manage",
    icon: UnorderedListOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "manage-products",
        path: `${APP_PREFIX_PATH}/manage/products`,
        title: "sidenav.manage.products",
        icon: InsertRowBelowOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "manage-products-list",
            path: `${APP_PREFIX_PATH}/manage/products/list`,
            title: "sidenav.manage.products.list",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "manage-products-category",
            path: `${APP_PREFIX_PATH}/manage/products/category`,
            title: "sidenav.manage.products.categories",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: "manage-stock-control",
        path: `${APP_PREFIX_PATH}/manage/stock`,
        title: "sidenav.manage.stock",
        icon: AppstoreOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "manage-stock-control-orders",
            path: `${APP_PREFIX_PATH}/manage/stock/orders`,
            title: "sidenav.manage.stock.orders",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "manage-stock-control-history",
            path: `${APP_PREFIX_PATH}/manage/stock/history`,
            title: "sidenav.manage.stock.history",
            icon: "",
            breadcrumb: false,
            submenu: [],
          } /*{
          key: 'manage-stock-control-takes',
          path: `${APP_PREFIX_PATH}/manage/stock/takes`,
          title: 'sidenav.manage.stock.takes',
          icon: '',
          breadcrumb: false,
          submenu: []
        }*/,
        ],
      },
      {
        key: "manage-staff",
        path: `${APP_PREFIX_PATH}/manage/staff`,
        title: "sidenav.manage.staff",
        icon: TeamOutlined,
        breadcrumb: false,
        submenu: cookieGETAdminID() === 2 ? megaAdminView : adminView,
      },
      /*{
      key: 'manage-customer-services',
      path: `${APP_PREFIX_PATH}/manage/customer-services`,
      title: 'sidenav.manage.customer',
      icon: UserSwitchOutlined,
      breadcrumb: false,
      submenu: [
        {
          key: 'manage-customer-services-repairs',
          path: `${APP_PREFIX_PATH}/manage/customer-services/repairs`,
          title: 'sidenav.manage.customer.repairs',
          icon: '',
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'manage-customer-services-buyback',
          path: `${APP_PREFIX_PATH}/manage/customer-services/buyback`,
          title: 'sidenav.manage.customer.buyback',
          icon: '',
          breadcrumb: false,
          submenu: []
        }
      ]
    }*/
    ],
  },
];

const reportingNavTree = [
  {
    key: "reporting",
    path: `${APP_PREFIX_PATH}/reporting`,
    title: "sidenav.reporting",
    icon: PieChartOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "reporting-transactions",
        path: `${APP_PREFIX_PATH}/reporting/transactions`,
        title: "sidenav.reporting.transaction",
        icon: CreditCardOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "reporting-transactions-completed",
            path: `${APP_PREFIX_PATH}/reporting/transactions-completed`,
            title: "sidenav.reporting.transaction.completed",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: "reporting-sales",
        path: `${APP_PREFIX_PATH}/reporting/sales`,
        title: "sidenav.reporting.sales",
        icon: BarChartOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "reporting-sales-period",
            path: `${APP_PREFIX_PATH}/reporting/sales/period`,
            title: "sidenav.reporting.sales.period",
            icon: "",
            breadcrumb: false,
            submenu: [],
          } /*
        {
          key: 'reporting-sales-comparison',
          path: `${APP_PREFIX_PATH}/reporting/sales/comparison`,
          title: 'sidenav.reporting.sales.comparisons',
          icon: '',
          breadcrumb: false,
          submenu: []
        },*/,
          {
            key: "reporting-sales-product",
            path: `${APP_PREFIX_PATH}/reporting/sales/product`,
            title: "sidenav.reporting.sales.sales",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "reporting-sales-locations",
            path: `${APP_PREFIX_PATH}/reporting/sales/locations`,
            title: "sidenav.reporting.sales.locations",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "reporting-sales-categories",
            path: `${APP_PREFIX_PATH}/reporting/sales/categories`,
            title: "sidenav.reporting.sales.categories",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "reporting-sales-till-categories",
            path: `${APP_PREFIX_PATH}/reporting/sales/till-categories`,
            title: "sidenav.reporting.sales.till-categories",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: "reporting-stock",
        path: `${APP_PREFIX_PATH}/reporting/stock`,
        title: "sidenav.reporting.stock",
        icon: AppstoreOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "reporting-stock-levels",
            path: `${APP_PREFIX_PATH}/reporting/stock/levels`,
            title: "sidenav.reporting.stock.levels",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          /*{
          key: 'reporting-stock-history',
          path: `${APP_PREFIX_PATH}/reporting/stock/history`,
          title: 'sidenav.reporting.stock.history',
          icon: '',
          breadcrumb: false,
          submenu: []
        }*/
        ],
      },
      /*{
      key: 'reporting-customer-services',
      path: `${APP_PREFIX_PATH}/reporting/customer-services`,
      title: 'sidenav.reporting.customer',
      icon: UserSwitchOutlined,
      breadcrumb: false,
      submenu: [
        {
          key: 'reporting-customer-services-repairs',
          path: `${APP_PREFIX_PATH}/reporting/customer/repairs`,
          title: 'sidenav.reporting.customer.repairs',
          icon: '',
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'reporting-customer-services-buyback',
          path: `${APP_PREFIX_PATH}/reporting/customer/buyback`,
          title: 'sidenav.reporting.customer.buyback',
          icon: '',
          breadcrumb: false,
          submenu: []
        }
      ]
    },*/
      {
        key: "reporting-banking",
        path: `${APP_PREFIX_PATH}/reporting/banking`,
        title: "sidenav.reporting.banking",
        icon: BankOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "reporting-banking-tenders",
            path: `${APP_PREFIX_PATH}/reporting/banking/tenders`,
            title: "sidenav.reporting.banking.tenders",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          /*{
          key: 'reporting-banking-endofday',
          path: `${APP_PREFIX_PATH}/reporting/banking/endofday`,
          title: 'sidenav.reporting.banking.endofday',
          icon: '',
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'reporting-banking-petty',
          path: `${APP_PREFIX_PATH}/reporting/banking/petty`,
          title: 'sidenav.reporting.banking.petty',
          icon: '',
          breadcrumb: false,
          submenu: []
        },*/
        ],
      },
      /*{
      key: 'reporting-accounting',
      path: `${APP_PREFIX_PATH}/reporting/accounting`,
      title: 'sidenav.reporting.accounting',
      icon: CalculatorOutlined,
      breadcrumb: false,
      submenu: [
        {
          key: 'reporting-accounting-bookkeeping',
          path: `${APP_PREFIX_PATH}/reporting/accounting/bookkeeping`,
          title: 'sidenav.reporting.accounting.bookkeeping',
          icon: '',
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'reporting-accounting-daily',
          path: `${APP_PREFIX_PATH}/reporting/accounting/daily`,
          title: 'sidenav.reporting.accounting.daily',
          icon: '',
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'reporting-accounting-monthly',
          path: `${APP_PREFIX_PATH}/reporting/accounting/monthly`,
          title: 'sidenav.reporting.accounting.monthly',
          icon: '',
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'reporting-accounting-quarterly',
          path: `${APP_PREFIX_PATH}/reporting/accounting/quarterly`,
          title: 'sidenav.reporting.accounting.quarterly',
          icon: '',
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'reporting-accounting-endofyear',
          path: `${APP_PREFIX_PATH}/reporting/accounting/endofyear`,
          title: 'sidenav.reporting.accounting.endofyear',
          icon: '',
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'reporting-accounting-payroll',
          path: `${APP_PREFIX_PATH}/reporting/accounting/payroll`,
          title: 'sidenav.reporting.accounting.payroll',
          icon: '',
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'reporting-accounting-gross',
          path: `${APP_PREFIX_PATH}/reporting/accounting/gross`,
          title: 'sidenav.reporting.accounting.gross',
          icon: '',
          breadcrumb: false,
          submenu: []
        },
      ]
    },*/
      {
        key: "reporting-auditing",
        path: `${APP_PREFIX_PATH}/reporting/auditing`,
        title: "sidenav.reporting.auditing",
        icon: ReloadOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "reporting-auditing-refunds",
            path: `${APP_PREFIX_PATH}/reporting/auditing/refunds`,
            title: "sidenav.reporting.auditing.refunds",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "reporting-auditing-discounts",
            path: `${APP_PREFIX_PATH}/reporting/auditing/discounts`,
            title: "sidenav.reporting.auditing.discounts",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
    ],
  },
];

const navigationConfig = [
  ...dashBoardNavTree,
  ...tillNavTree,
  ...setupNavTree,
  ...manageNavTree,
  ...reportingNavTree,
];

export default navigationConfig;
