import antdJaJP from "antd/es/locale/ja_JP";
import jaMsg from "../locales/ja_JP.json";

const JaLang = {
  antd: antdJaJP,
  locale: "ja-JP",
  messages: {
    ...jaMsg,
  },
};
export default JaLang;
