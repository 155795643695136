import React, { createContext, useState } from "react";

export const TransactionContext = createContext();

export const TransactionProvider = ({ children }) => {
  const [transactionStatus, setTransactionStatus] = useState(-1);
  const [previousTransaction, setPreviousTransaction] = useState(null);
  const [errorReport, setErrorReport] = useState(null);

  const startTransaction = () => {
    setTransactionStatus(1);
  };

  const completeTransaction = (transactionData) => {
    setTransactionStatus(0);
    setPreviousTransaction(transactionData);
  };

  const failTransaction = (error) => {
    setTransactionStatus(2);
    setErrorReport(error);
  };

  const clearTransaction = () => {
    setTransactionStatus(-1);
    setPreviousTransaction(null);
    setErrorReport(null);
  };

  const transactionContextValue = {
    transactionStatus,
    previousTransaction,
    errorReport,
    startTransaction,
    completeTransaction,
    failTransaction,
    clearTransaction,
  };

  return (
    <TransactionContext.Provider value={transactionContextValue}>
      {children}
    </TransactionContext.Provider>
  );
};
