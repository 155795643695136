import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { cookieGETAuthenticator } from "cookie/general";
import { cookieGETAdminID } from "cookie/general";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/dashboards`}
          component={lazy(() => import(`./dashboards`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/setup`}
          component={lazy(() => import(`./setup`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/manage`}
          component={lazy(() => import(`./manage`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/reporting`}
          component={lazy(() => import(`./reporting`))}
        />
        {cookieGETAdminID() === 2 ? (
          <Redirect
            from={`${APP_PREFIX_PATH}`}
            to={`${APP_PREFIX_PATH}/dashboards`}
          />
        ) : (
          <Redirect
            from={`${APP_PREFIX_PATH}`}
            to={`${APP_PREFIX_PATH}/manage`}
          />
        )}
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
