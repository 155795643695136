import React, { createContext, useState } from "react";

export const PaymentContext = createContext();

export const PaymentProvider = ({ children }) => {
  /** @typedef {Object} Payment
   * @property {string} paymentID
   * @property {string} name
   * @property {number} value
   * @property {string} [changeGiven]
   */

  const [payments, setPayments] = useState([]);

  const addPayment = (payment) => {
    setPayments((prevPayments) => [...prevPayments, payment]);
  };

  const clearPayments = () => {
    setPayments([]);
  };

  const calculateTotalPayments = () => {
    return payments.reduce((total, payment) => total + payment.value, 0);
  };

  const paymentContextValue = {
    payments,
    addPayment,
    clearPayments,
    calculateTotalPayments,
  };

  return (
    <PaymentContext.Provider value={paymentContextValue}>
      {children}
    </PaymentContext.Provider>
  );
};
