import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Loading from "components/shared-components/Loading";

const Apps = ({ match }) => (
  <Suspense fallback={<Loading cover="content" />}>
    <Switch>
      <Route
        path={`${match.url}/main`}
        component={lazy(() => import("./main/Main.js"))}
      />
      <Route
        path={`${match.url}/login`}
        component={lazy(() => import("./login/Login.js"))}
      />
      <Route
        path={`${match.url}/stock-take`}
        component={lazy(() => import("./stock-take/StockTake.js"))}
      />
      <Route
        path={`${match.url}/session-report`}
        component={lazy(() => import("./session/SessionReport.js"))}
      />
      <Redirect from={`${match.url}`} to={`${match.url}/login`} />
    </Switch>
  </Suspense>
);

export default Apps;
