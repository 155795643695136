import { API_BASE_URL, API_BRANCH, API_MISC } from "constants/ApiConstant";

export function API_TillLogin(data) {
  return fetch(
    (process.env.REACT_APP_API_URL || API_BASE_URL) + API_MISC + "login/0",
    {
      method: "POST",
      mode: "cors",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    },
  ).then((response) => response.json());
}

export function API_MISCUpdatePass(data) {
  return fetch(
    (process.env.REACT_APP_API_URL || API_BASE_URL) + API_MISC + "1",
    {
      method: "PATCH",
      mode: "cors",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    },
  ).then((response) => response.json());
}

export function API_BRANCHGetAllByAdminID(id) {
  return fetch(
    (process.env.REACT_APP_API_URL || API_BASE_URL) +
      API_BRANCH +
      "byAdminID/" +
      id,
  ).then((response) => response.json());
}

export function API_BRANCHFindByAreaID(id) {
  return fetch(
    (process.env.REACT_APP_API_URL || API_BASE_URL) +
      API_BRANCH +
      "byAreaID/" +
      id,
  ).then((response) => response.json());
}
